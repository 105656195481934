import React from "react";
import { Contexto } from "./index";

const Carregando = () => {
  const { carregando } = React.useContext(Contexto);

  return (
    <React.Fragment>
      {carregando && (
        <div className="modal-panel hide-for-print">
          <div className="modal">Aguarde...</div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Carregando;
