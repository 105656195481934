import React, { useEffect, useState } from "react";
import Filtros from "./Filtros";
import Carregando from "./Carregando";
import Erro from "./Erro";

export const Contexto = React.createContext({});

const RelatorioAgendamento = (props) => {
  const [erro, setErro] = useState(null);
  const [data, setData] = useState(props.data);
  const [sede, setSede] = useState(null);
  const [sedes, setSedes] = useState([]);
  const [nucleo, setNucleo] = useState(null);
  const [nucleos, setNucleos] = useState([]);
  const [agendamentoTipo, setAgendamentoTipo] = useState(null);
  const [agendamentoTipos, setAgendamentoTipos] = useState([]);
  const [agendamentos, setAgendamentos] = useState(null);
  const [carregando, setCarregando] = useState(true);

  const values = {
    erro,
    setErro,
    data,
    setData,
    sede,
    setSede,
    sedes,
    setSedes,
    nucleo,
    setNucleo,
    nucleos,
    setNucleos,
    agendamentoTipo,
    setAgendamentoTipo,
    agendamentoTipos,
    setAgendamentoTipos,
    agendamentos,
    setAgendamentos,
    carregando,
    setCarregando,
  };

  return (
    <Contexto.Provider value={values}>
      <Erro />
      <Filtros />
      <Carregando />
    </Contexto.Provider>
  );
};

export default RelatorioAgendamento;
