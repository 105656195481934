import React, { useEffect } from "react";
import { Contexto } from "./index";
import axios from "axios";

const MENSAGEM_DE_ERRO_GENERICA =
  "Ocorreu um erro. Por favor, tente novamente. Se o erro persistir, contate os administradores.";

const Filtros = () => {
  const {
    data,
    setData,
    sede,
    setSede,
    sedes,
    setSedes,
    nucleo,
    setNucleo,
    nucleos,
    setNucleos,
    agendamentoTipo,
    setAgendamentoTipo,
    agendamentoTipos,
    setAgendamentoTipos,
    setCarregando,
    erro,
    setErro,
  } = React.useContext(Contexto);

  const gerarRelatorio = (e) => {
    e.preventDefault();
    if (!data) {
      return alert("Por favor, selecione uma data");
    }

    setCarregando(true);
    e.target.submit();
  };

  const changeData = (e) => {
    setData(e.target.value);
  };

  const changeSede = (e) => {
    setSede(e.target.value);
  };

  const changeNucleo = (e) => {
    setNucleo(e.target.value);
  };

  const changeAgendamentoTipo = (e) => {
    setAgendamentoTipo(e.target.value);
  };

  useEffect(() => {
    axios
      .get("/relatorios/lista_sedes")
      .then((resp) => {
        setSedes(resp.data);
      })
      .catch(() => {
        setErro({ mensagem: MENSAGEM_DE_ERRO_GENERICA });
      })
      .finally(() => {
        setCarregando(false);
      });
  }, []);

  useEffect(() => {
    setNucleo(null);
    setNucleos([]);
    setAgendamentoTipo(null);
    setAgendamentoTipos([]);
  }, [sede]);

  useEffect(() => {
    if (sede) {
      const agendamentoTipoId = agendamentoTipo || "";

      setCarregando(true);
      axios
        .get(`/relatorios/lista_nucleos_tipos/${sede}/${agendamentoTipoId}`)
        .then((resp) => {
          setNucleos(resp.data.nucleos);
          if (!agendamentoTipo) {
            setAgendamentoTipos(resp.data.tipos);
          }
        })
        .catch((err) => {
          setErro({ mensagem: err.message });
        })
        .finally(() => {
          setCarregando(false);
        });
    }
  }, [sede, agendamentoTipo]);

  return (
    <form action="/relatorios/agendamento" onSubmit={gerarRelatorio}>
      <div className="row">
        <div className="small-12 medium-3 columns">
          <label>Data</label>
          <input
            type="date"
            name="data"
            value={data || ""}
            onChange={(e) => changeData(e)}
          />
        </div>
        <div className="small-12 medium-3 columns">
          <label>Sede</label>
          <select
            onChange={(e) => changeSede(e)}
            name="sede"
            value={sede || ""}
          >
            <option value="">- Todas -</option>
            {sedes.map((item) => (
              <option key={`sedes${item.id}`} value={item.id}>
                {item.nome}
              </option>
            ))}
          </select>
        </div>
        <div className="small-12 medium-3 columns">
          <label>Tipo de agendamento</label>
          <select
            onChange={(e) => changeAgendamentoTipo(e)}
            name="agendamento_tipo"
            value={agendamentoTipo || ""}
          >
            <option value="">- Todos -</option>
            {agendamentoTipos.map((item) => (
              <option key={`tipos${item.id}`} value={item.id}>
                {item.nome}
              </option>
            ))}
          </select>
        </div>
        <div className="small-12 medium-3 columns">
          <label>Núcleo</label>
          <select
            onChange={(e) => changeNucleo(e)}
            name="nucleo"
            value={nucleo || ""}
          >
            <option value="">- Todos -</option>
            {nucleos.map((item) => (
              <option key={`nucleos${item.id}`} value={item.id}>
                {item.nome}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row">
        <div className="small-12 columns acoes-relatorio">
          <button type="submit" className="button" disabled={erro}>
            Gerar relatório
          </button>
          <button
            type="button"
            className="button"
            onClick={() => window.print()}
          >
            <i className="fa fa-print"></i>
            <span className="icon-text"> Imprimir</span>
          </button>
        </div>
      </div>
    </form>
  );
};

export default Filtros;
