import React, { useEffect } from "react";
import { Contexto } from "./index";

const Erro = () => {
  const { erro, setErro } = React.useContext(Contexto);

  useEffect(() => {
    if (erro && erro.tempo) {
      setTimeout(() => {
        setErro(null);
      }, erro.tempo);
    }
  }, [erro]);

  return (
    <React.Fragment>
      {erro && (
        <div className="callout alert dynamic-alert">
          {erro.mensagem}
          {!erro.tempo && <span onClick={() => setErro(null)}>x</span>}
        </div>
      )}
    </React.Fragment>
  );
};

export default Erro;
