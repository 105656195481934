import React, { useState } from "react";

const CadastroModal = ({ validation }) => {
  const [show, setShow] = useState(!validation);

  return (
    <React.Fragment>
      {show && (
        <div class="modal-panel">
          <div class="modal">
            <div class="callout warning">
              <strong>Atenção: </strong> Preencha corretamente a cidade onde
              você mora, pois você só será atendido mediante apresentação do
              comprovante de residência da cidade escolhida.
            </div>
            <button class="button primary" onClick={() => setShow(false)}>
              Entendi
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CadastroModal;
